import { Dialog, Transition } from "@headlessui/react";
import clsx from "clsx";
import React, { Fragment } from "react";
import EPButton from "../EPButton";

function EPModal({
  isOpen,
  onClose,
  disableOutSideClick,
  title = "",
  children,
}) {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 my-auto overflow-y-auto rounded"
        onClose={onClose}
      >
        <div className="absolute inset-0 flex items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay
              className={clsx(
                "fixed inset-0 bg-black/50  transition-opacity",
                disableOutSideClick && "pointer-events-none"
              )}
            />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block w-full overflow-hidden rounded-lg bg-[#272727] text-left align-bottom shadow-xl transition-all sm:my-8 sm:max-w-lg sm:align-middle">
              <div className="relative px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                {/* close button on right */}
                <button
                  className="absolute right-4 top-1 text-2xl transition hover:text-primary-content/70 active:text-primary-content/90 md:text-3xl"
                  onClick={onClose}
                  aria-label="close"
                >
                  &times;
                </button>
                <div className="">
                  <div className="mt-3 text-center sm:mx-4 sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-center text-lg font-medium leading-6 text-primary-content"
                    >
                      {title}
                    </Dialog.Title>
                    <div className="mb-3 mt-2  max-h-[70vh] overflow-auto ">
                      {children}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default EPModal;

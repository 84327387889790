import axios from "axios";
import { CookieService } from "service/CookieService";
import { delay } from "utils/delay";
import { EP_API_END_POINT } from "../index";
import { withProtectedTask } from ".";

export const epApiAxios = axios.create({
  baseURL: EP_API_END_POINT + "/v1",
  timeout: 120000,
});
epApiAxios.interceptors.request.use(addTokenInterceptor);
epApiAxios.interceptors.response.use(responseInterceptor, processNetworkErrors);

async function addTokenInterceptor(config) {
  const headers = config.headers || {};
  const accessToken = getAccessToken();

  if (accessToken) headers["auth-token"] = accessToken;

  config.headers = headers;
  return config;
}

function responseInterceptor(config) {
  return config;
}

async function processNetworkErrors(err) {
  if (err.response?.status === 403) {
    setAccessToken();
    setRefreshToken();
    window.location.reload();
  }
  return await handleTokenExpire(err);
}

async function handleTokenExpire(err) {
  const shouldRetry = isTokenExpired(err);

  if (shouldRetry) {
    const token = await getNewAccessToken();
    err.config.headers["auth-token"] = token;
    return new Promise(resolve => {
      delay(() => {
        err.config.__retryCount = (err.config.__retryCount || 0) + 1;
        return resolve(axios(err.config));
      });
    });
  } else {
    return Promise.reject(err);
  }
}

function isTokenExpired(error) {
  if (!error.config) return false;
  const isTokenExpired = error.response?.status === 401;
  return isTokenExpired;
}

const getNewAccessToken = withProtectedTask(async () => {
  const refresh_token = getRefreshToken();
  if (!refresh_token) {
    setAccessToken();
    setRefreshToken();
    window.location.reload();
    return;
  }
  const res = await epApiAxios.post("/user/token/refresh", {
    refresh_token,
  });

  setAccessToken(res.data.access_token);
  setRefreshToken(res.data.refresh_token);
  return res.data.access_token;
});

export function getAccessToken() {
  return (
    CookieService.getAccessToken() ||
    (typeof window !== "undefined" && window.accessToken)
  );
}

export function getRefreshToken() {
  return (
    CookieService.getRefreshToken() ||
    (typeof window !== "undefined" && window.refreshToken)
  );
}

export function setAccessToken(token) {
  CookieService.setAccessToken(token);
  if (typeof window !== "undefined" && CookieService.getAccessToken() !== token)
    // when the cookies are not set, the token is not set in the window object
    window.accessToken = token;
}

export function setRefreshToken(token) {
  CookieService.setRefreshToken(token);
  if (
    typeof window !== "undefined" &&
    CookieService.getRefreshToken() !== token
  )
    // when the cookies are not set, the token is not set in the window object
    window.refreshToken = token;
}

export class User {
  constructor(obj) {
    this.name = obj.name;
    this.userId = obj.userId;
    this.role = obj.role;
    this.anonymous = obj.anonymous;
  }
  isAnonymous() {
    return this.role === "ANONYMOUS_USER";
  }
  static from(data) {
    return new User({
      name: data?.username || "guest",
      userId: data?.user_id,
      role: data?.role,
      anonymous: data?.role === "ANONYMOUS_USER",
    });
  }
}

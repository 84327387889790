import axios from "axios";
import { StorageService } from "service/StorageService";
import { delay } from "utils/delay";
import { API_END_POINT } from "../index";

export const apiAxios = axios.create({
  baseURL: API_END_POINT + "/v1",
  timeout: 120000,
});
// apiAxios.interceptors.request.use(addTokenInterceptor);
apiAxios.interceptors.response.use(responseInterceptor, processNetworkErrors);

function addTokenInterceptor(config) {
  const headers = config.headers || {};
  const accessToken = StorageService.getAccessToken();
  if (accessToken) headers["auth-token"] = accessToken;

  config.headers = headers;
  return config;
}

function responseInterceptor(config) {
  return config;
}

async function processNetworkErrors(err) {
  if (err.response?.status === 403) {
    StorageService.setAccessToken();
    StorageService.setRefreshToken();
    StorageService.setWId();
    window.location.href = "/";
  }
  return await handleTokenExpire(err);
}

async function handleTokenExpire(err) {
  const shouldRetry = isTokenExpired(err);

  if (shouldRetry) {
    const token = await getNewAccessToken();
    err.config.headers["auth-token"] = token;
    return new Promise(resolve => {
      delay(() => {
        err.config.__retryCount = (err.config.__retryCount || 0) + 1;
        return resolve(axios(err.config));
      });
    });
  } else {
    return Promise.reject(err);
  }
}

function isTokenExpired(error) {
  if (!error.config) return false;
  const isTokenExpired = error.response?.status === 401;
  return isTokenExpired;
}

const getNewAccessToken = withProtectedTask(async () => {
  const res = await apiAxios.post("/user/token/refresh", {
    refresh_token: StorageService.getRefreshToken(),
  });

  StorageService.setAccessToken(res.data.access_token);
  StorageService.setRefreshToken(res.data.refresh_token);
  return res.data.access_token;
});

/**
 * @template T
 * @param {{ (): Promise<T> }} fn
 * @returns  {Promise<any>}
 *
 * I don't know why I made this unstable.
 */
export function withProtectedTask(fn) {
  const cachedPromise = {};

  return function wrapper() {
    const hasArgs = !!arguments.length;
    const hash = arguments.length ? getObjectHash(arguments) : "__DEFAULT__";

    if (hasArgs) {
      // Not fully tested.
      console.warn(
        /** BTW, I'm using in prod 😅😂 */
        "[unsafe_withProtectedTask] Be careful if you are willing to use in production..."
      );
    }

    if (cachedPromise[hash]) {
      console.log("reusing existing network call.");
      return cachedPromise[hash];
    }

    cachedPromise[hash] = fn
      .apply(null, arguments)
      .then(data => {
        cachedPromise[hash] = null;
        return data;
      })
      .catch(err => {
        cachedPromise[hash] = null;
        return Promise.reject(err);
      });

    return cachedPromise[hash];
  };
}

function getObjectHash(obj) {
  //bechmark: https://codesandbox.io/s/getobjecthash-m78qb?file=/src/index.js
  return JSON.stringify(obj);
}

//@ts-check
export class PopupWindowManager {
  constructor(url, windowName, onPopUpBlocked) {
    this._url = url;
    this._windowName = windowName;
    this._tab = null;
    this._listeners = [];
    this._cancelEventListeners = [];
    this._timerId = null;
    this._onPopUpBlocked = onPopUpBlocked;

    this._brodcastevents = this._brodcastevents.bind(this);
    this._checkIfPopupCanceled = this._checkIfPopupCanceled.bind(this);
  }

  openNewTab() {
    if (this._tab && !this._tab.closed) {
      this._tab.close();
    }

    this._tab = window.open(
      this._url,
      this._windowName,
      "toolbar=no, menubar=no, width=600, height=700, left=50%"
    );

    if (!this._tab || this._tab.closed) {
      if (this._onPopUpBlocked) this._onPopUpBlocked();
      return;
    }

    window.addEventListener("message", this._brodcastevents);
    this._checkIfPopupCanceled();
  }

  onCancel(fn) {
    this._cancelEventListeners.push(fn);

    return () => {
      this._cancelEventListeners = this._cancelEventListeners.filter(
        listener => listener !== fn
      );
    };
  }

  dismiss() {
    clearTimeout(this._timerId);
  }

  onMessage(fn) {
    this._listeners.push(fn);

    return () => {
      this._listeners = this._listeners.filter(listener => listener !== fn);
    };
  }

  destroy() {
    this.dismiss();
    window.removeEventListener("message", this._brodcastevents);
    if (this._tab && !this._tab.closed) {
      this._tab.close();
    }
    this._listeners = [];
    this._cancelEventListeners = [];
  }

  _checkIfPopupCanceled() {
    if (this._tab.closed) {
      this._brodcastCancelevents();
      return;
    }

    this._timerId = setTimeout(this._checkIfPopupCanceled, 1000);
  }

  _brodcastevents(event) {
    if (this._tab !== event.source) {
      return;
    }

    if (this._tab) {
      this._listeners.forEach(listener => listener(event));
    }
  }
  _brodcastCancelevents() {
    this._cancelEventListeners.forEach(listener => listener());
  }
}

export const TokenPopupManager = {
  _popupManager: null,
  init(onPopupBlock) {
    this.destroyPopup();
    this._popupManager = new PopupWindowManager(
      "about:blank",
      "token-reciver",
      onPopupBlock
    );
    this._popupManager.openNewTab();
  },
  destroyPopup() {
    if (this._popupManager) {
      this._popupManager.destroy();
    }
  },
  async getTokenFromPopup(url, onPopupClosed) {
    return new Promise((resolve, reject) => {
      this._popupManager._tab.location = url;
      const popupManager = this._popupManager;
      // const popupManager = new PopupWindowManager(
      //   url,
      //   "token-reciver",
      //   onPopupBlock
      // );
      // this._popupManager = popupManager;

      popupManager.onMessage(event => {
        resolve(event);
        popupManager.destroy();
      });

      popupManager.onCancel(() => {
        // reject('Popup Closed');
        onPopupClosed();
        popupManager.destroy();
      });
    });
  },
};

function generateKey(key) {
  return "__epicplay__." + key;
}

function selectStorage(useSessionStorage) {
  if (typeof window === "undefined") return null;
  try {
    return !useSessionStorage ? localStorage : sessionStorage;
  } catch {
    return null;
  }
}
const _localstorage = {
  getItem(key, fromSessionStorage = false) {
    key = generateKey(key);
    const storage = selectStorage(fromSessionStorage);

    const val = storage?.getItem(key);

    if (val === null) return undefined;

    try {
      return JSON.parse(val);
    } catch (error) {
      return val; // if not, simply return the value.
    }
  },

  setItem(key, value, saveToSessionStorage = false) {
    key = generateKey(key);
    const storage = selectStorage(saveToSessionStorage);

    if (value === undefined || value === null) {
      return storage?.removeItem(key);
    }

    if (typeof value === "object") {
      value = JSON.stringify(value);
    }

    storage?.setItem(key, value);
  },
};

export const StorageService = {
  getAccessToken() {
    return _localstorage.getItem("accessToken");
  },
  setAccessToken(value) {
    return _localstorage.setItem("accessToken", value);
  },
  getRefreshToken() {
    return _localstorage.getItem("refreshToken");
  },
  setRefreshToken(value) {
    return _localstorage.setItem("refreshToken", value);
  },
  getUserId() {
    return _localstorage.getItem("userId");
  },
  setUserId(value) {
    return _localstorage.setItem("userId", value);
  },
  getRecentlyPlayedGames() {
    return _localstorage.getItem("recentlyPlayedGames");
  },
  setRecentlyPlayedGames(value) {
    return _localstorage.setItem("recentlyPlayedGames", value);
  },
  get(key, fromSessionStorage = false) {
    return _localstorage.getItem(key, fromSessionStorage);
  },
  set(key, value, saveToSessionStorage = false) {
    return _localstorage.setItem(key, value, saveToSessionStorage);
  },
};

import axios from "axios";
import { epApiAxios } from "config/axios/ep";
import { EP_API_END_POINT } from "config/index";
import { User } from "src/model/User";
import { CookieService } from "./CookieService";
import { COOKIES } from "constants/cookies.const";

export const AuthService = {
  async createAnonymousUser() {
    const res = await epApiAxios.post(`/user/anon/create`, {
      refurl: CookieService.get(COOKIES.QUERY_STRING),
    });
    return res?.data;
  },
  async getGoogleRedirectionUrl(
    redirectUrl = process.env.NEXT_PUBLIC_LOGIN_URL + "/google/callback"
  ) {
    const res = await epApiAxios.get(
      `/users/oauth/google/redirect_url?redirection_url=${redirectUrl}`
    );
    return res?.data;
  },
  async loginWithGoogle(data) {
    const res = await epApiAxios.post(
      `/users/oauth/google/login`,
      {
        ...data,
        redirection_url: process.env.NEXT_PUBLIC_LOGIN_URL + "/google/callback",
      } //
    );
    return res?.data;
  },
  async loginWithFacebook(accessToken) {
    const res = await epApiAxios.post(`/user/oauth/facebook/login`, {
      access_token: accessToken,
    });
    return res?.data;
  },
  async getUserDetails() {
    const res = await epApiAxios.get(`/user/me`);
    return User.from(res?.data);
  },
  async getUserExp() {
    const res = await epApiAxios.get(`/xp/me`);
    return res?.data;
  },
  async changeUserName(username) {
    const res = await epApiAxios.put(`/user/username/update`, {
      username,
    });
    return res?.data;
  },
};

import EPButton from "components/UI/EPButton";
import useService from "hooks/useService";
import { BiLoaderAlt } from "react-icons/bi";
import Logo from "/public/svgs/logo.svg";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { AuthService } from "service/AuthService";
import { validateUsername, withDefaultUIHandler } from "src/utils";
import { TokenPopupManager } from "utils/PopupWindowManager";
import { FcGoogle } from "react-icons/fc";
import { useRouter } from "next/router";
import { epToast } from "utils/epToast";
import EPModal from "components/UI/EPModal";
import clsx from "clsx";
import {
  getAccessToken,
  getRefreshToken,
  setAccessToken,
  setRefreshToken,
} from "config/axios/ep";

const LOGIN_SUCCESS_MESSAGE = "LOGIN_SUCCESS";
const LOGIN_CANCELLED_MESSAGE = "LOGIN_CANCELLED";

/**
 * @type { Context<{
 *   authUser: User,
 *   isLoadingProfile: boolean,
 *   getProfile:  (...parameters) => Promise<T>,
 *   onRequestAuth:  (...parameters) => Promise<T>
 *  handleProviderLogin:  (...parameters) => Promise<T>,
 *   isAuth: boolean,
 *   isUser: boolean,
 *  }>}
 *
 */
const AuthContext = createContext(null);

export function AuthContextProvider(props) {
  const {
    data: user,
    isLoading: isLoadingProfile,
    call: getUserDetails,
  } = useService(AuthService.getUserDetails);
  const [openAuthModal, setOpenAuthModal] = useState(null);
  const authModalOptionsRef = useRef(null);
  const createAnonymousUserApi = useService(AuthService.createAnonymousUser);
  const onAuthDoneCB = useRef(null);
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(true);
  const [setupProfileOpen, setSetupProfileOpen] = useState(false);

  const isAuth = useMemo(() => {
    if (!user || isLoading) return false;
    return !user.isAnonymous();
  }, [user, isLoading]);

  useEffect(() => {
    initLogin();
  }, []);

  async function initLogin() {
    setIsLoading(true);

    try {
      const acc_tok = getAccessToken();

      if (acc_tok) {
        await getUserDetails();
      } else {
        const data = await createAnonymousUserApi.call();
        const tokens = data.tokens;
        setAuthTokens(tokens);
        await getUserDetails();
      }
    } catch {
    } finally {
      setIsLoading(false);
    }
  }

  const onRequestAuth = useCallback(
    (loginCallback, options) => {
      return new Promise((resolve, reject) => {
        onAuthDoneCB.current = loginCallback;
        authModalOptionsRef.current = options;
        if (isAuth) {
          onAuthDoneCB.current?.(true);
          resolve(true);
          return;
        }
        handleProviderLogin()
        setOpenAuthModal(true);

        window.addEventListener("message", e => {
          if (e.origin !== window.location.origin) return;
          if (e.data === LOGIN_SUCCESS_MESSAGE) {
            resolve(true);
          }
          if (e.data === LOGIN_CANCELLED_MESSAGE) {
            reject(new Error("Cancelled"));
          }
        });
      });
    },
    [isAuth]
  );

  async function onSuccess(data) {
    setOpenAuthModal(false);
    window.postMessage(LOGIN_SUCCESS_MESSAGE, window.location.origin);
    onAuthDoneCB.current?.(true);
    onAuthDoneCB.current = null;
  }
  const { call: startGoogleLogin } = useService(
    AuthService.getGoogleRedirectionUrl
  );

  useEffect(() => {
    if (!router.query.google_state) return;
    const state = router.query.google_state;
    const code = router.query.google_code;

    if (state && code) {
      signInUsingToken(state, code)
        .then(() => {
          router.replace("/");
        })
        .catch(err => {
          router.replace("/");
        });
    }
  }, [router.query.google_state]);

  const signInUsingToken = useCallback(
    async (state, code) => {
      try {
        if (!state || !code) {
          throw new Error("Failed to sign up : User Denied!");
        }
        const data = await AuthService.loginWithGoogle({ state, code });

        if (!setAuthTokens(data.tokens)) {
          window.accessToken = tokens?.access_token;
          window.refreshToken = tokens?.refresh_token;
        }
        if (data?.new_user) {
          setSetupProfileOpen(true);
        }
        await getUserDetails();
        // means old USER
        onSuccess(data);
      } catch (error) {
        withDefaultUIHandler(Promise.reject(error), false, true, false);
        // return props.history.replace('/');
      }
    },
    [getUserDetails]
  );
  const handleProviderLogin = useCallback(async () => {
    function handleBlockedPopup(url) {
      window.location.href = url;
    }

    try {
      TokenPopupManager.init(() => handleBlockedPopup(url));
      const { url } = await startGoogleLogin();
      const event = await TokenPopupManager.getTokenFromPopup(url, () => {});
      if (!event.data.state || !event.data.code) {
        throw "Token missing";
      }
      await signInUsingToken(event.data.state, event.data.code);
    } catch (error) {
      const err = new Error(error);
      withDefaultUIHandler(Promise.reject(err), false, true, false);
    }
  }, [signInUsingToken, startGoogleLogin]);

  const values = useMemo(() => {
    return {
      authUser: user || null,
      ...(user || {}),
      onRequestAuth,
      handleProviderLogin,
      getProfile: getUserDetails,
      isLoadingProfile,
      isAuth,
      // isUser, // checks if user tokens are present
    };
  }, [
    user,
    onRequestAuth,
    getUserDetails,
    handleProviderLogin,
    isAuth,
    isLoadingProfile,
    // isUser,
  ]);

  return (
    <AuthContext.Provider value={values}>
      {props.children}

      <>
        {/* 
        // !!Modal Login is not being used
        <AuthDrawer
          open={openAuthModal}
          onLoginSuccess={onSuccess}
          getUserDetails={getUserDetails}
          onClose={() => {
            onAuthDoneCB.current?.(false);
            setOpenAuthModal(false);
            authModalOptionsRef.current = null;
            onAuthDoneCB.current = null;
            window.postMessage(LOGIN_CANCELLED_MESSAGE, window.location.origin);
          }}
          options={authModalOptionsRef.current}
        /> */}

        <SetupProfileContent
          open={setupProfileOpen}
          close={() => {
            setSetupProfileOpen(false);
          }}
        />
      </>
    </AuthContext.Provider>
  );
}

// !!Modal Login is not being used
/*
function AuthDrawer(props) {
  return (
    <Dialog
      unmount={false}
      open={!!props.open}
      onClose={props.onClose}
      className="fixed inset-0 z-[100] flex items-end  overflow-y-auto "
    >
      <Dialog.Overlay className="fixed inset-0 z-40 bg-black/30" />

      <Transition
        show={!!props.open}
        as={React.Fragment}
        enter="transition duration-100 ease-out"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="z-50 flex h-auto max-h-[80vh] w-screen overflow-hidden md:h-screen md:max-h-none">
          <div className="flex w-full flex-col justify-between overflow-hidden rounded-t-lg bg-[var(--bg-modal)] text-center align-middle  shadow-xl md:w-1/4 md:min-w-[350px] md:rounded-none">
            <div>
              <Dialog.Title className="flex items-center justify-end p-6 py-2 text-2xl  font-bold text-primary-content md:text-4xl">
                <button
                  className="text-2xl md:text-4xl"
                  onClick={props.onClose}
                  aria-label="close"
                >
                  &times;
                </button>
              </Dialog.Title>
              {props.options?.topContent && props.options?.topContent}
              <Dialog.Description>
                <div className="p-6 pb-8 pt-2">
                  <LoginContent
                    onSuccess={props.onLoginSuccess}
                    getUserDetails={props.getUserDetails}
                  />
                </div>
              </Dialog.Description>
            </div>
          </div>
        </div>
      </Transition>
    </Dialog>
  );
}
*/

/**
 * @return {{
 *   authUser: User,
 *   isLoadingProfile: boolean,
 *   getProfile:  (...parameters) => Promise<T>,
 *   onRequestAuth:  (...parameters) => Promise<T>
 *   isAuth: boolean
 *  }}
 *
 */
export default function useAuthContext() {
  const ctx = useContext(AuthContext);
  if (!ctx) {
    throw new Error("Context is provider.");
  }

  return ctx;
}

function LoginContent({ onLoginSuccess, onSuccess, getUserDetails }) {
  const { handleProviderLogin } = useAuthContext();

  return (
    <div>
      {/* <EPButton colorScheme="facebook" leftIcon={<FaFacebook />}>
        Facebook
      </EPButton> */}

      <button
        onClick={handleProviderLogin}
        className="flex w-full items-center gap-2 rounded-md bg-[#1F87FC] p-1 font-medium text-primary-content"
      >
        <span className="self-start rounded-md bg-white p-3">
          <FcGoogle size={30} />
        </span>
        <span className="flex-1">Sign in with Google</span>
      </button>
    </div>
  );
}

function SetupProfileContent({ open, close = () => {} }) {
  const [name, setName] = useState("");
  const { authUser, getProfile } = useAuthContext();
  const changeUserNameApi = useService(AuthService.changeUserName);
  const handleChangeUserName = async () => {
    if (!validateUsername(name)) return;
    await changeUserNameApi.call(name);
    await getProfile();
    epToast.success({
      title: "Username updated successfully!",
    });

    close();
  };
  // useEffect(() => {
  //   if (!open) return;
  //   setName(authUser?.name || "");
  // }, [open]);
  return (
    <EPModal
      title={
        <>
          <div className="flex flex-col gap-2">
            <span className="mx-auto flex   items-center justify-center rounded-full  ">
              <Logo alt="logo" height="36px" width="36px" />
            </span>
            Thanks for Joining EpicPlay!
          </div>
        </>
      }
      isOpen={open}
      onClose={() => {
        close();
        setName("");
      }}
    >
      <>
        <div className="mt-4">
          <div className="mt-4">
            <label
              htmlFor="username"
              className="mb-2 block text-center  font-medium text-gray-300"
            >
              Enter the username you want to use.
            </label>
            <input
              name="username"
              type="text"
              className="block w-full rounded-md border border-gray-600 bg-gray-700 px-3 py-2 text-gray-200 shadow-sm placeholder:text-gray-400 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm"
              placeholder="Enter Your Name"
              value={name}
              onChange={e => setName(e.target.value)}
            ></input>
          </div>
          {/* <div className="mb-4 mt-6 text-sm text-secondary-content">
            Note: Username can be changed only twice.
          </div> */}
        </div>
        <div className="mt-4 flex items-center justify-center gap-2">
          <EPButton
            variant="secondary"
            bg="bg-[#555555]"
            onClick={() => {
              close();
            }}
            className="flex-1  hover:bg-[#555555]/80"
          >
            {" "}
            Cancel
          </EPButton>
          <EPButton
            variant="primary"
            onClick={async () => {
              await handleChangeUserName();
            }}
            disabled={
              name === "" ||
              name === authUser.name ||
              changeUserNameApi.isLoading
            }
            className={clsx(
              "h-full flex-1 items-center justify-center",
              name === "" && "opacity-50"
            )}
          >
            {changeUserNameApi.isLoading ? (
              <div className="flex animate-spin items-center justify-center ">
                <BiLoaderAlt size={24} />
              </div>
            ) : (
              "Save Changes"
            )}
          </EPButton>
        </div>
      </>
    </EPModal>
  );
}

export function setAuthTokens(tokens) {
  setAccessToken(tokens?.access_token);
  setRefreshToken(tokens?.refresh_token);
  return true;
}

// check if auth tokens are present in cookies
export function isAuthTokens() {
  return getAccessToken() && getRefreshToken();
}

import { deleteCookie, getCookie, setCookie } from "cookies-next";
function generateKey(key) {
  return "__epicplay__." + key;
}
const _cookieStore = {
  getItem(key, allValue) {
    key = generateKey(key);

    const val = getCookie(key);
    if (val === null) return undefined;
    try {
      return JSON.parse(allValue ? val : val);
    } catch (error) {
      return allValue ? val : val; // if not, simply return the value.
    }
  },

  setItem(key, value) {
    key = generateKey(key);

    if (typeof value === "object") {
      value = JSON.stringify(value);
    }

    setCookie(key, value, {
      expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 30),
    });
  },
  deleteItem(key) {
    key = generateKey(key);
    deleteCookie(key);
  },
};

export const CookieService = {
  getAccessToken() {
    return _cookieStore.getItem("accessToken");
  },
  setAccessToken(value) {
    return _cookieStore.setItem("accessToken", value);
  },
  getRefreshToken() {
    return _cookieStore.getItem("refreshToken");
  },
  setRefreshToken(value) {
    return _cookieStore.setItem("refreshToken", value);
  },

  get(key, allValue = false) {
    return _cookieStore.getItem(key, allValue);
  },
  set(key, value) {
    return _cookieStore.setItem(key, value);
  },
  remove(key) {
    return _cookieStore.deleteItem(key);
  },
};

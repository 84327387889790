import { useState, useCallback, useMemo, useRef } from "react";
import { withDefaultUIHandler } from "../utils";

/**
 * @template T
 * @param {() => Promise<T>} service
 * @param {any[]} deps
 * @returns {{data: T | null, error: Error, isLoading: Boolean, call: (...parameters) => Promise<T> }}
 */
export default function useService(service, deps, formator) {
  const [result, setResult] = useState({
    data: null,
    error: null,
    isLoading: false,
    isLoaded: false,
  });
  const oldResultRef = useRef(result);

  oldResultRef.current = result;

  const call = useCallback((...args) => {
    return withDefaultUIHandler(async () => {
      setResult(v => ({ ...v, isLoading: true }));
      try {
        const data = await service(...args);

        const newData = formator
          ? formator(data, oldResultRef.current.data, args)
          : data;
        setResult(v => ({
          ...v,
          data: newData,
          error: null,
          isLoading: false,
          isLoaded: true,
        }));
        return data;
      } catch (error) {
        setResult(v => ({ ...v, error, isLoading: false, isLoaded: true }));
        return Promise.reject(error);
      }
    });
  }, deps);
  function clear() {
    setResult({ data: null, error: null, isLoading: false });
    oldResultRef.current = { data: null, error: null, isLoading: false };
  }
  const opt = useMemo(() => {
    return { ...result, call, clear };
  }, [result, call]);

  return opt;
}

import React from "react";
import classes from "./styles.module.scss";
import clsx from "clsx";
import { forwardRef } from "react";
import Link from "next/link";
import { cn } from "utils/styling";

/**
 * @typedef {'primary' | 'secondary' | 'link' | 'ghost'} Variant
 * @description The available variants for the button component.
 * @typedef {'sm' | 'md' | 'lg' | 'auto'} Size
 * @description The available sizes for the button component.
 *
 */

/**
 * Renders a button or a link with a given variant and children.
 *
 * @param {Variant} props.variant - The variant of the button component.
 * @param {boolean} props.rounded - Whether the button component should be rounded.
 * @param {React.ReactNode} props.children - The children to render inside the button component.
 * @param {function} [props.onClick] - The click handler for the button component.
 * @param {string} [props.href] - The URL for the link component.
 * @param {string} [props.className] - The CSS class name to apply to the button component.
 * @returns {React.ReactElement} The rendered button or link component.
 */

const EPButton = forwardRef(function EPButton(
  {
    variant = "primary",
    rounded = false,
    bg,
    children,
    onClick,
    href,
    disabled,
    notReady,
    className,

    ...restProps
  },
  ref
) {
  const variantClassName =
    variant === "primary"
      ? "bg-primary text-primary-content px-4 py-2 hover:bg-[var(--primary-color-hover)] active:bg-[var(--primary-color-active)]"
      : variant === "secondary"
      ? "text-primary-content px-4 py-2 hover:bg-opacity-70 active:bg-opacity-90"
      : variant === "icon"
      ? "bg-transparent hover:bg-neutral-400/30"
      : classes[variant];

  const Component = href ? Link : "button";
  const bgColorClass =
    variant === "secondary" ? (bg ? bg : "bg-secondary") : "";

  const notReadyClass =
    // shimmering
    "animate-pulse";
  const combinedClassNames = cn(
    "text-base font-medium rounded-lg cursor-pointer",
    variantClassName,
    rounded && classes.roundedIcon,
    disabled && "bg-opacity-50 cursor-not-allowed hover:bg-opacity-50",
    notReady && notReadyClass,
    bgColorClass,
    className
  );
  return (
    <Component
      ref={ref}
      className={combinedClassNames}
      onClick={onClick}
      href={href}
      disabled={disabled}
      {...restProps}
    >
      {children}
    </Component>
  );
});

export default EPButton;

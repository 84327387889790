import { StorageService } from "service/StorageService";

export const ENV = process?.env?.NEXT_PUBLIC_ENV;
export const isProduction = ENV === "production";
export const isDev = ENV === "development";

const __TWEAK_API_ENDPOINT__ = "__TWEAK_API_ENDPOzINT__";
const __TWEAK_EP_API_ENDPOINT__ = "__TWEAK_EO_API_ENDPOzINT__";
export const API_END_POINT =
  !isProduction && StorageService.get(__TWEAK_API_ENDPOINT__)
    ? StorageService.get(__TWEAK_API_ENDPOINT__)
    : process.env.NEXT_PUBLIC_API_END_POINT;

export const EP_API_END_POINT =
  !isProduction && StorageService.get(__TWEAK_EP_API_ENDPOINT__)
    ? StorageService.get(__TWEAK_EP_API_ENDPOINT__)
    : process.env.NEXT_PUBLIC_EP_API_END_POINT;
